@import url('https://fonts.googleapis.com/css?family=Work+Sans:400,600');

.container {
	width: 100%;
	margin: 0 auto;
}

header {
  background: #55d6aa;
}

header::after {
  content: '';
  display: table;
  clear: both;
}

nav {
  float: middle;
}

nav ul {
  margin: 0;
  padding: 0;
  /*list-style: none;*/
}

nav li {
  display: inline-block;
  margin-left: 35px;
  padding-top: 23px;
  position: relative;
}

nav a {
  color: #FFF8DC;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 17px;
}

nav a:hover {
  color: #FFF8DC;
}

nav a::before {
  content: '';
  display: block;
  height: 5px;
  background-color:#FFF8DC;

  position: absolute;
  top: 0;
  width: 0%;

  transition: all ease-in-out 250ms;
}

nav a:hover::before {
  width: 100%;
}