.experience_container {
    gap: 2rem;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 90%;
    display: flex;
    grid-template-columns: 1fr 1fr 1fr;
    flex-wrap: wrap;
}

.experience_container > div {
    background-image: linear-gradient(45deg, rgb(212, 148, 75) 1%,  rgb(166,104,220,1) 99%);
    padding: 1.5rem 3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: 0.5s;
    width: 75%;
    margin: auto;
    box-sizing: content-box;
}

.experience_container > div:hover {
    cursor: default;
    transform: scale(1.03); 
}

.experience_content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    row-gap: 1rem;
}

.experience_details {
    display: flex;
    gap: 1rem;
}

.experience_details-icon {
    margin-top: 0px;
    color: #606060;
}

h4 {
    margin: 0px;
    padding: 0px;
    font-size: 12px;
}

text-light {
    font-size: 10px;
}

h3 {
    display:  center;
    position: center;
    text-decoration: none;
    text-align: center;
    margin-top: 0px;
}

h5 {
    font-size: 10px;
    margin:  0px;
    font-style: normal;
    color:  blanchedalmond;
    margin-top: 5px;
}

@media (max-width: 480px) {
    .experience_container > div {
        padding: 1.25rem 1.5rem;

    }
}

