.App {
  text-align: center;
  pointer-events:visibleFill;
  min-height: 100vh;
  height: 100vh;
}



/*@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}*/

.Title {
  pointer-events:none;
  min-height: 10vh;
  align-items:center;
  justify-content:center;
  color: white;
  display: flex;
  font-size: 20px;
  text-align:center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  pointer-events:visibleFill;

}

.App-link {
  color: #61dafb;
  pointer-events:visibleFill;
}

/*@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/
