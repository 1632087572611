


.timeline__event__pic {
  width:92px;
  border-radius: 50%
}

.timeline {
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  position: center;

}
.timeline__event {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  margin: 20px 0;
  border-radius: 6px;
  align-self: center;
  width: 90%;
}
.timeline__event:nth-child(2n+1) {
  flex-direction: row-reverse;
}
.timeline__event:nth-child(2n+1) .timeline__event__date {
  border-radius: 0 6px 6px 0;
}
.timeline__event:nth-child(2n+1) .timeline__event__content {
  border-radius: 6px 0 0 6px;
}
.timeline__event:nth-child(2n+1) .timeline__event__icon:before {
  content: "";
  width: 2px;
  height: 100%;
  background: #f6a4ec;
  position: absolute;
  top: 0%;
  left: 50%;
  right: auto;
  z-index: -1;
}
.timeline__event:nth-child(2n+1) .timeline__event__icon:after {
  content: "";
  width: 100%;
  height: 2px;
  background: grey;
  position: absolute;
  right: 0;
  z-index: -1;
  top: 50%;
  left: auto;
  transform: translateY(-50%);
  -webkit-animation: fillLeft 2s forwards 4s ease-in-out;
          animation: fillLeft 2s forwards 4s ease-in-out;
}
.timeline__event__title {
  font-size: 1.2rem;
  line-height: 1.4;
  text-transform: uppercase;
  font-weight: 600;
  color: grey;
  letter-spacing: 1.5px;
}
.timeline__event__content {
  padding: 20px;
  font-size: 0.9rem;
  box-shadow: 0 30px 60px -12px rgba(255, 255, 255, 0.3), 0 18px 36px -18px rgba(255, 255, 255, 0.3), 0 -12px 36px -8px rgba(255, 255, 255, 0.025);
  background: #fff;
  width: calc(40vw - 84px);
  border-radius: 0 6px 6px 0;
}
.timeline__event__date {
  color: white;
  font-size: 1.0rem;
  font-weight: 600;
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0 20px;
  border-radius: 6px 0 0 6px;
}
.timeline__event__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9251ac;
  padding: 20px;
  align-self: center;
  margin: 0 10px;
  background: grey;
  border-radius: 100%;
  width: 10px;
  box-shadow: 0 30px 60px -12px rgba(255, 255, 255, 0.25), 0 18px 36px -18px rgba(255, 255, 255, 0.3), 0 -12px 36px -8px rgba(255, 255, 255, 0.025);
  padding: 40px;
  height: 10px;
  position: relative;
}
.timeline__event__icon i {
  font-size: 32px;
}
.timeline__event__icon:before {
  content: "";
  width: 2px;
  height: 100%;
  background: #f6a4ec;
  position: absolute;
  top: 0%;
  z-index: -1;
  left: 50%;

}
.timeline__event__icon:after {
  content: "";
  width: 100%;
  height: 2px;
  background: grey;
  position: absolute;
  left: 0%;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  -webkit-animation: fillLeftOdd 2s forwards 4s ease-in-out;
          animation: fillLeftOdd 2s forwards 4s ease-in-out;
}
.timeline__event__description {
  flex-basis: 60%;
}
.timeline__event--type2:after {
  background: #555ac0;
}
.timeline__event--type2 .timeline__event__date {
  color: #87bbfe;
  background: #555ac0;
}
.timeline__event--type2:nth-child(2n+1) .timeline__event__icon:before, .timeline__event--type2:nth-child(2n+1) .timeline__event__icon:after {
  background: #87bbfe;
}
.timeline__event--type2 .timeline__event__icon {
  background: #87bbfe;
  color: #555ac0;
}
.timeline__event--type2 .timeline__event__icon:before, .timeline__event--type2 .timeline__event__icon:after {
  background: #87bbfe;
}
.timeline__event--type2 .timeline__event__title {
  color: #555ac0;
}
.timeline__event--type3:after {
  background: #24b47e;
}
.timeline__event--type3 .timeline__event__date {
  color: #aff1b6;
  background-color: #24b47e;
}
.timeline__event--type3:nth-child(2n+1) .timeline__event__icon:before, .timeline__event--type3:nth-child(2n+1) .timeline__event__icon:after {
  background: #aff1b6;
}
.timeline__event--type3 .timeline__event__icon {
  background: #aff1b6;
  color: #24b47e;
}
.timeline__event--type3 .timeline__event__icon:before, .timeline__event--type3 .timeline__event__icon:after {
  background: #aff1b6;
}
.timeline__event--type3 .timeline__event__title {
  color: #24b47e;
}
.timeline__event:last-child .timeline__event__icon:before {
  content: none;
}

@media (max-width: 786px) {
  .timeline__event {
    flex-direction: column;
    align-self: center;

  }
  .timeline__event__content {
    width: 90%;
    align-self:center;
    border-radius: 10px 10px 10px 10px;

  }
  .timeline__event__icon {
    border-radius: 6px 6px 0 0;
    width: 100%;
    margin: 0;
    box-shadow: none;
    display: none;

  }
  .timeline__event__icon:before, .timeline__event__icon:after {
    display: none;
  }
  .timeline__event__date {
    border-radius: 0;
    padding: 20px;
  }
  .timeline__event:nth-child(2n+1) {
    flex-direction: column;
    align-self: center;
  }
  .timeline__event:nth-child(2n+1) .timeline__event__date {
    border-radius: 0;
    padding: 20px;
  }
  .timeline__event:nth-child(2n+1) .timeline__event__icon {
    border-radius: 6px 6px 0 0;
    margin: 0;
  }
}
@-webkit-keyframes fillLeft {
  100% {
    right: 100%;
  }
}
@keyframes fillLeft {
  100% {
    right: 100%;
  }
}
@-webkit-keyframes fillTop {
  100% {
    top: 100%;
  }
}
@keyframes fillTop {
  100% {
    top: 100%;
  }
}
@-webkit-keyframes fillLeftOdd {
  100% {
    left: 100%;
  }
}
@keyframes fillLeftOdd {
  100% {
    left: 100%;
  }
}