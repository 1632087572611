.portfolio-project {
	color: black;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 10px 0;
    background: lightgray;
    padding: 1.5rem 1.5rem;
    border-radius: 0.6rem;
    border: 1px solid transparent;
    transition: 0.5s;
    width: 200px;
    margin: auto;
    margin-top: 30px;
	box-shadow: 10px 10px 7px rgb(200 200 200 / 0.6);
	height: 300px;
  	flex-direction: column;
}

.portfolio-project:hover {
	background: white;
	transform: scale(1.1); 
	box-shadow: 0px 0px 0px rgb(200 200 200 / 0.8);


}

@media (max-width: 786px) {
	.portfolio-container {
		padding: 0rem 0rem;
	}
}

.portfolio-container {
	width: 90%;
	margin: auto;
	display: grid;
	justify-content: center;
	align-items: center;
    grid-template-columns: repeat(auto-fit, 280px);
    row-gap: 7px;
}

.portfolio-title {
    text-align: center;
	position: center;
    margin-top: -10px;
    padding: 0px;
    font-size: 12px;
}

.portfolio-img {
	margin-top: 5px;
	margin-bottom: 5px;
	height: 100px;
	width: 200px;
	object-fit: contain;
}

.portfolio-text {
	font-size: 15px;
	padding: 0px;
}

.portfolio-tags {
	font-weight: bold;
	font-size: 13px;
	margin-top: 5px;
  	margin-top: auto;
}

.github-icon {
	margin-top:  -17px;
	padding: 0px;
}