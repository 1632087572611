$purple: #8D53FF;
$pink: #CA6BE6;
$white: #f8f8f8;
$dark: #1f1f1f;
$red: #FF6057;
$yellow: #FFBD2E;
$green: #27C93F;
$lightgray: #c9c9c9;

$transition: all 250ms ease;

.shadowed {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,0.4);
}

h1 {
  color:$lightgray;

  font-size: 2rem;
  text-align: center;
  @media only screen and (min-width: 940px) {
    font-size: 3rem;
    text-align: left;
  }
}

h2 {
  color:$lightgray;

  font-size: 1.25rem;
  text-align: center;
  @media only screen and (min-width: 940px) {
    font-size: 2rem;
    text-align: left;
  }
}

.hand {
  animation-name: wave;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave {
  0% { transform: rotate( 0.0deg) }
  10% { transform: rotate(14.0deg) }
  20% { transform: rotate(-8.0deg) }
  30% { transform: rotate(14.0deg) }
  40% { transform: rotate(-4.0deg) }
  50% { transform: rotate(10.0deg) }
  60% { transform: rotate( 0.0deg) }
  100% { transform: rotate( 0.0deg) }
}

@media (max-width: 700px) {
  .img {
    height: 10px;
  }

}